


















































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Seminar } from "@/core/models";
import { defaultPfp } from "@/core/utils/constants";
import api from "@/core/utils/api";

interface PublishableSeminar {
  title: string;
  description: string;
  lecturer: string;
  price: number;
}
@Component
export default class SeminarList extends Vue {
  @Prop({ default: () => false }) admin!: boolean;
  @Getter("seminars/loading") loading!: boolean;
  @Getter("seminars/retrieved") retrieved!: boolean;
  @Getter("seminars/seminars") seminars!: Seminar[];
  @Action("seminars/getAll") getAll!: () => Promise<void>;

  // this allows me to import a function from the vuex store
  // here i am importing it so I can use it in any component
  // the vuex store allows me to have and mutate the same data in any component, regardless of the component tree structure
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  // Indicator that something is loading
  publishLoading = false;
  // This will control if the popup is shown or not
  publishModal = false;
  // This will hold the selected seminar, that will later be published
  selected: Seminar | null = null;
  // This will hold the new object that will be sent to the backend
  publishable: PublishableSeminar | null = null;
  // This function will be called when the user clicks on the "Publish button"
  // Set selected seminar, show dialog, setup new publishable object
  selectSeminar(s: Seminar) {
    this.publishModal = true;
    this.selected = s;
    this.publishable = {
      title: s.name,
      description: s.description,
      lecturer: s.owner?.username || "",
      price: 99,
    };
  }
  // This will be called when the user clicks cancel or closes the dialog
  cancelPublish() {
    this.publishModal = false;
    this.selected = null;
    this.publishable = null;
  }
  // This will be called when submitting the form or clicking the "Publish this seminar button"
  async publishSeminar() {
    // copy the publishable seminar
    const p = JSON.parse(
      JSON.stringify(this.publishable),
    ) as PublishableSeminar;
    this.publishable = null;
    this.publishModal = false;
    console.log("[publish seminar] data is", p);

    // let's setup the actions to be sent
    // typo - controller is called Seminar not Seminars
    // just noticed that the loader is shown on all instances - let's fix that
    this.publishLoading = true;
    try {
      await api.post("/api/Seminar/Publish", p);
      // let's translate the messages too
      const m = this.$t("seminars.dash.form.success").toString();
      this.displaySnackbar(m);
    } catch (error) {
      const m = this.$t("seminars.dash.form.fail").toString();
      this.displaySnackbar(m);
    }
    this.publishLoading = false;

    // remove the selected seminar after the request is done
    this.selected = null;
  }

  // Now let's do translations

  usersLimit = 5;
  defaultPfp = defaultPfp;
  created() {
    if (!this.retrieved) this.getAll();
  }
}
